.submit-button{
  background-color: rgb(13,13,16);
  color: rgb(255,255,255);
  -webkit-appearance: button;
  appearance: button;
  backface-visibility: hidden;
  border: 0;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px rgba(50, 50, 93, .1);
  cursor: pointer;
  height: 44px;
  margin-top: 12px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  transform: translateZ(0);
  transition: all .2s ease, box-shadow .08s ease-in;
  width: 100%;
}

.submit-button-text-container{
  box-sizing: border-box;
}

.pay-with{
  left: 0;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all .2s ease;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  margin: 0;
}

.processing{
  opacity: 0;
  /* transform: translateX(16px); */
  left: 0;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all .2s ease;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  margin: 0;
}

/* UTILS */

.display-block{
  display: block;
}

.display-none{
  display: none;
}