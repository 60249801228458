.container{
  width: 100%;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  font-size: 16px;
  justify-content: center;
  -webkit-justify-content: center;
  min-height: 0;
}

.multi-item{
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  position: relative;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  margin-top: 0;
  max-width: 920px;
}

@media only screen and (min-width: 992px){
  .multi-item{
    transform: translateY(max(48px, calc(50vh - 55%)));
    flex-direction: row;
    -webkit-flex-direction: row;
  }

  .container::before{
    background: #ffffff;
    height: 100%;
    content: " ";
    position: fixed;
    right: 0;
    top: 0;
    transform-origin: right;
    width: 50%;
    box-shadow: 15px 0 30px 0 rgba(0, 0, 0, .18);
  }

}

.product-overview{
  background-color: #ffffff;
  width: 380px;
  margin-bottom: 0;
}

.header{
  min-height: 28px ;
}

@media only screen and (max-width: 991.98px){

  .multi-item{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .product-overview{
    width: 100%;
  }

  .product-summary{
    display: none !important;
  }

  .product-summary-info{
    display: none;
  }

  .header {
    height: 60px;
    box-sizing: border-box;
    left: 0;
    margin-bottom: 0;
    padding: 16px;
    position: relative;
    right: 0;
    top: 0;
    transition: background-color .15s ease, box-shadow .15s ease-out;
    width: 100vw;
    min-height: 28px;
    z-index: 12;
  }

  .order-details-items>li>div {
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .line-item-image-container{
    width: 100px !important;
    height: 100px !important;
    margin: 0 auto 20px !important;
  }

  .line-item-product-container{
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -moz-box-align: center !important;
    -ms-flex-align: center !important;
    text-align: center;
  }

  .line-item-product-name>span{
    font-size: 16px !important;
    color: #131316;
  }

  .line-item-total-amount{
    padding: 10px;
    margin: 0 auto !important;
  }

  .line-item-total-amount>span{
    font-size: 28px !important;
  }

  .order-details-footer-subtotal, .order-details-total{
    display: none !important;
  }
  
  .product-payment{
    padding-bottom: 20px;
  }

  .checkout-footer{
    position: relative !important;
  }

  .submit-button{
    margin-top: 0 !important;
  }

  .process-description{
    padding-top: 0 !important;
  }

}

.header-content{
  align-items: stretch;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

@media only screen and (max-width: 991.98px){
  .header-content{
    margin: auto;
    max-width: 380px;
    align-items: stretch;
    -webkit-align-items: stretch;
  }
}

.header-business{
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-basis: 0;
  -webkit-flex-basis: 0;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  align-items: center;
  -webkit-align-items: center;
  box-sizing: border-box;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
}

.header-merchant-logo{
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  max-width: 100%;
  min-width: 0;
}

.header-image{
  display: flex;
  display: -webkit-flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-basis: auto;
  -webkit-flex-basis: auto;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 2px 5px 0 rgba(50,50,93, .1);
  height: 28px;
  margin-right: 8px;
  width: 28px;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  box-sizing: border-box;
}

.header-image-icon{
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-style: none;
  box-sizing: border-box;
}

.header-business-name{
  color: hsla(0, 0%, 10%, .9);
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.order-summary-column{
  margin: 0 auto;
  max-width: 380px;
  box-sizing: border-box;
}

.product-summary{
  margin-bottom: 16px;
  display: flex;
  display: -webkit-flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: left;
  -webkit-justify-content: left;
  margin-top: 32px;
  text-align: left;
  flex-direction: column;
  -webkit-flex-direction: column;
  box-sizing: border-box;
}

.product-summary-info{
  cursor: default;
  pointer-events: none;
  display: flex;
  display: -webkit-flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.product-summary-name{
  word-break: break-word;
  color: hsla(0,0%,10%,.6);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  box-sizing: border-box;
}

.product-summary-amounts-container{
  position: relative;
  box-sizing: border-box;
}

.product-summary-total{
  opacity: 1;
  box-sizing: border-box;
}

.product-summary-total-amount-container{
  opacity: 1;
  pointer-events: none;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  box-sizing: border-box;
}

.product-summary-total-amount{
  font-size: 36px;
  margin: 2px 0 3px;
  font-weight: 600;
  box-sizing: border-box;
}

/* ORDER DETAILS */

.order-details{
  display: block;
  margin-left: -5px;
  padding-left: 5px;
  margin-bottom: 32px;
  margin-top: 32px;
  box-sizing: border-box;
  overflow-y: visible;
}

.order-details-items{
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.order-details-items>li{
  opacity: 1;
  transform: scale(1);
  height: auto;
  margin-bottom: 16px;
  display: block;
  box-sizing: border-box;
}

.order-details-items>li>div{
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin-left: 0;
  transition: margin-left .4s linear;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  box-sizing: border-box;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

.flex-column-break{
  flex-basis: 100%;
  width: 0;
  box-sizing: border-box;
}

.line-item-image-container{
  margin-right: 16px;
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  height: 42px;
  width: 42px;
  justify-content: center;
  -webkit-justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  box-sizing: border-box;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
}

.line-item-image{
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;
  border-style: none;
  box-sizing: border-box;
}

.line-item-product-container{
  flex-basis: 0;
  -webkit-flex-basis: 0;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: baseline;
  -webkit-align-items: baseline;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.line-item-product-name{
  order: 0;
  flex-basis: min-content !important;
  flex-grow: 1 !important;
  -webkit-flex-grow: 1 !important;
  width: auto;
  box-sizing: border-box;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
}

.line-item-product-name>span{
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}

.line-item-description-container{
  width: 100%;
  order: 3;
  box-sizing: border-box;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

.line-item-description{
  order: 3;
  margin-right: 16px;
  flex: 2 1 max-content;
  -webkit-flex: 2 1 max-content;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
}

.line-item-description>span{
  color: hsla(0, 0%, 10%, .5);
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.line-item-total-amount{
  order: 1;
  margin-left: 8px;
  flex-shrink: 0;
  flex-basis: auto;
  box-sizing: border-box;
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  max-width: 100%;
  min-width: 0;
}

.line-item-total-amount>span{
  font-weight: 500;
  font-size: 14px;
}

.order-details-footer{
  box-sizing: border-box;
}

.order-details-footer-subtotal{
  margin-left: 58px;
  border-bottom: 1px solid hsla(0. 0%, 10%, .1);
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

.order-details-footer-subtotal>span{
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}

.order-details-subtotal-amount{
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.03rem;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  box-sizing: border-box;
}

.order-details-total{
  margin-left: 58px;
  padding-bottom: 16px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
}

.order-details-total>span{
  box-sizing: border-box;
}

.order-details-total-amount{
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

/* PAYMENT SECTION */

.product-payment{
  margin-bottom: 0;
  width: 380px;
  height: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.checkout-payment{
  margin: 0 auto;
  max-width: 380px;
  box-sizing: border-box;
  min-height: 672px;
}

.payment-request-header{
  display: inherit;
  height: auto;
  position: relative;
  box-sizing: border-box;
}

.payment-header-container{
  display: block;
  opacity: 1;
  width: 100%;
  box-sizing: border-box;
}

.payment-header{
  padding-bottom: 16px;
  padding-top: 16px;
  box-sizing: border-box;
}

.payment-header>div{
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  box-sizing: border-box;
}

.payment-iframe{
  /* height: 637px;
  min-height: 400px; */
  box-sizing: border-box;
  transition: all .2s ease;
  /* border: 1px blue solid; */
  /* opacity: 0; */
}

.process-description{
  width: 85%;
  margin: 0 auto;
  padding-top: 20px;
  opacity: 1;
  transition: all .3s ease;
}

.yaatt{
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}

.preview-amount{
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.wauff{
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.25;
  text-align: center;
  font-size: 18px;
}

.wauff>div, .tp>div, .advice>div{
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.wauff>span{
  font-weight: bold;
}

.tp{
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 1.25;
  font-size: 18px;
  text-align: center;
}

.tp>span, .advice>span{
  font-weight: bold;
}

.advice{
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 1.25;
  font-size: 18px;
  text-align: center;
}

/* UTILS */

.display-block{
  display: block;
}

.display-none{
  display: none;
}

.checkout-footer{
  bottom: 0;
  left: 0;
  margin: 12px 0;
  position: absolute;
  background-color: inherit;
  height: auto;
  padding: 0;
  max-width: 350px;
  width: auto;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex-direction: column;
  -webkit-flex-direction: column;
  line-height: 16px;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  box-sizing: border-box;
  text-align: left;
}

.checkout-footer a{
  text-decoration: underline;
  color: black;
  font-weight: 600;
  display: block;
}

.notifications{
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.25;
  transition: all .4s ease;
}

.notifications-animations{
  animation: fade 3s infinite linear;
}

@keyframes fade {
  0%, 100% {opacity: 0;}
  50% {opacity: 1;}
}